import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"

import HomepageBlogListHeader from "../components/HomepageBlogListHeader"
import HomePageHeroSection from "../components/HomePageHeroSection"
const CaseStudy = loadable(() => import("../components/caseStudy"))
const BlogList = loadable(() => import("../components/BlogList"))

const IndexPage = ({ data }) => {
  return (
    <FadeIn>
      <SEO
        title="Home | Brand Consultancy"
        description="Growing & evolving brands that keep people healthy"
      />
      <HomePageHeroSection />
      <div>
        {data.contentfulCaseStudiesList.caseStudies.map((caseStudy, i) => {
          return (
            <CaseStudy
              caseStudy={caseStudy}
              id={caseStudy.id}
              key={`case-study-${caseStudy.id}`}
              containerStyle={
                i === 0
                  ? {
                      marginTop: "5vh",
                    }
                  : null
              }
            />
          )
        })}
      </div>
      <div>
        <HomepageBlogListHeader />
      </div>
      <BlogList blogPosts={data.contentfulBlogsList.blogPosts} />
    </FadeIn>
  )
}

export const query = graphql`
  query HomePageQuery {
    contentfulCaseStudiesList(name: { eq: "Homepage Case Studies" }) {
      caseStudies {
        id
        name
        noWrap
        textColor
        isLive
        slug
        client {
          id
          name
        }
        serviceTypes {
          title
        }
        introResult
        featuredImages {
          images {
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 70)
            file {
              details {
                size
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
    contentfulBlogsList(name: { eq: "Homepage Blog Posts" }) {
      id
      blogPosts {
        id
        slug
        title
        subtitle
      }
    }
  }
`

export default IndexPage
