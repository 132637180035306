// extracted by mini-css-extract-plugin
export var hideForMobile = "HomePageHeroSection-module--hide-for-mobile--SUea-";
export var hideForTablet = "HomePageHeroSection-module--hide-for-tablet--PWux9";
export var hideForLaptop = "HomePageHeroSection-module--hide-for-laptop--9M2FM";
export var hideForDesktop = "HomePageHeroSection-module--hide-for-desktop--pkYuI";
export var hideForExtraLarge = "HomePageHeroSection-module--hide-for-extra-large--WVEwD";
export var showForMobileOnly = "HomePageHeroSection-module--show-for-mobile-only--rNFQP";
export var showForTabletOnly = "HomePageHeroSection-module--show-for-tablet-only--1BnqF";
export var showForLaptopOnly = "HomePageHeroSection-module--show-for-laptop-only--8hrDg";
export var showForDesktopOnly = "HomePageHeroSection-module--show-for-desktop-only--TI2w3";
export var showForExtraLargeOnly = "HomePageHeroSection-module--show-for-extra-large-only--lcHdl";
export var fullPageContainer = "HomePageHeroSection-module--full-page-container--r+997";
export var heroContainer = "HomePageHeroSection-module--heroContainer--aOeOR";
export var heroTitleContainer = "HomePageHeroSection-module--heroTitleContainer--yH5w-";
export var heroFlagContainer = "HomePageHeroSection-module--heroFlagContainer--EMFLq";
export var mouseDownIcon = "HomePageHeroSection-module--mouseDownIcon--eMWFN";
export var servicesContainer = "HomePageHeroSection-module--servicesContainer--9dM9D";
export var serviceContainer = "HomePageHeroSection-module--serviceContainer--U3Fix";
export var serviceNumber = "HomePageHeroSection-module--serviceNumber--ZHBJq";
export var serviceTitle = "HomePageHeroSection-module--serviceTitle--6tjQO";
export var servicesDescription = "HomePageHeroSection-module--servicesDescription--vtmr4";
export var serviceTypesContainer = "HomePageHeroSection-module--serviceTypesContainer--lDoDI";
export var serviceType = "HomePageHeroSection-module--serviceType--2j+Mo";