import React from "react"
import { Link } from "gatsby"
import * as styles from "./HomepageBlogListHeader.module.scss"

function BlogList() {
  return (
    <div className={styles.container}>
      <div className={styles.blogListHeaderContainer}>
        <div className="title-container">
          <div className={styles.blogListHeaderSubtitle}>
            Features. Insights. Opinions.
          </div>
          <div className={styles.blogListHeaderTitle}>It’s The Juice… </div>
        </div>
        <Link
          to="/blog"
          className={"underline-2"}
        >
          See All
        </Link>
      </div>
    </div>
  )
}

export default BlogList
